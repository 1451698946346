import React from 'react'
import {
  BackLink,
  Block,
  BlockImage,
  Content,
  HeadContent,
  HeadLine,
  ImageWrapper,
  PageName,
  Text,
  Wrapper,
} from '../styles/brandStoryPage'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import SubTitle from '../components/Title/SubTitle'
import SEO from '../components/seo'
import NextLink from '../components/NextLink'
import PageTransition from '../components/Animation/PageTransition'

const CONTENT_LIST = [
  {
    en: 'Feeling',
    label: '直感',
    image: 'photo/feeling.jpg',
    text:
      '直感で選ぶことをお勧めしています。「何となく気になった」「引かれている気がする」という感覚を信じてみて欲しいと思います。その感覚は無意識に受け取った反応である可能性が高いからです。',
  },
  {
    en: 'Pure',
    label: '無垢',
    image: 'photo/pure.jpg',
    text:
      '思考では、欲や願望が強すぎて感覚が狂わされてしまう恐れがあります。心の反応に素直に従ってみる方がより深い選択ができると思います。相性の良い石とご縁がある事を願っています。',
  },
]

const ChoicePage = () => {
  return (
    <>
      <SEO
        title="選び方"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Wrapper>
        <HeadLine>
          <BackLink to="/howtowear">←</BackLink>
          <PageName>選び方</PageName>
          <div></div>
        </HeadLine>
        <ImageWrapper>
          <Image filename="photo/choice-bracelet.jpg" />
        </ImageWrapper>
        <Content>
          <HeadContent>
            <CategoryTitle en="Choice" label="選び方" color="white" />
            <Text>
              自分の直感を信じてみて下さい。 ご縁のある石が不思議と分かります。
              また、ストーンリーディング（石選び）も出来ますので
              その際はお問い合わせ下さい。
            </Text>
          </HeadContent>

          {CONTENT_LIST.map(({ en, label, image, text }) => {
            return (
              <Block key={en}>
                <SubTitle en={en} label={label} />
                <BlockImage>
                  <Image filename={image} />
                </BlockImage>
                <Text>{text}</Text>
              </Block>
            )
          })}
          <NextLink label="アクセサリー" path={'/accessory'} />
        </Content>
        <PageTransition />
      </Wrapper>
    </>
  )
}

export default ChoicePage
